import { useNavigate, useParams } from "@solidjs/router";
import { onMount } from "solid-js";
import { ThreeDotLoader } from "~/widgets/button";

export default function PayURedirect() {
  const params = useParams();

  onMount(async () => {
    const navigate = useNavigate();
    navigate(
      `/buy-gift-card/${params.brandIdOrKey}/order?orderId=${params.orderId}`
    );
  });

  return (
    <div class="flex h-full w-full items-center justify-center">
      <ThreeDotLoader color="#333" />
    </div>
  );
}
